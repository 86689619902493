import { Box, Button, Center, Group, Stack, Text } from '@mantine/core'
import { useCompleteOnboarding } from 'api/query/onboarding'
import { useOnboarding } from 'hooks/useOnboarding'
import { useOnboardingContext } from 'layout/onboarding.layout'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { OnboardingSteps } from 'utils/steps'

export function CompletedOnboarding() {
  const { t } = useTranslation()
  const { setStep } = useOnboardingContext()
  const { redirectToCorrectStep } = useOnboarding()

  const { mutateAsync: completeOnboarding, isPending } = useCompleteOnboarding()

  useEffect(() => {
    setStep(OnboardingSteps.Completed)
    redirectToCorrectStep()
  }, [redirectToCorrectStep, setStep])

  return (
    <>
      <Center mt={200}>
        <Stack>
          <Text fz={'32px'} fw={700} lh={'40px'} maw={467} ta={'center'}>
            {t('onboarding:completed.title')}
          </Text>
          <Text fz={'28px'} fw={600} lh={'40px'} maw={467} ta={'center'}>
            {t('onboarding:completed.description')}
          </Text>

          <Center mt={20}>
            <Button loading={isPending} onClick={() => completeOnboarding()}>
              {t('onboarding:completed.go-to-dashboard')}
            </Button>
          </Center>
        </Stack>
      </Center>
    </>
  )
}
