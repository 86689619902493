import { ActionIcon, Box, Button, Flex, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconPlus } from '@tabler/icons-react'
import { ColumnDef } from '@tanstack/react-table'
import { Tenant } from 'api/domain/entities/tenant'
import { TenantStatus } from 'api/dto/tenant'
import { useListTenants } from 'api/query/tenant'
import { Aside } from 'components/aside/aside'
import { AsideEditTenantContent } from 'components/aside/content/aside-edit-tenant-content'
import { AddTenantDrawer } from 'components/drawer/add.tenant'
import { Loading } from 'components/loading/loading'
import { AugmentedColumn, Table } from 'components/table'
import { ClipboardText } from 'components/clipboard/clipboard-text'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getTenantStatusColor } from 'utils/status'

export function Tenants() {
  const { t } = useTranslation()
  const [opened, { open, close }] = useDisclosure()
  const { data: tenants, isLoading: isLoadingTenants } = useListTenants()
  const [selectedTenant, setSelectedTenant] = useState<Tenant | undefined>()

  const columns: AugmentedColumn<Tenant>[] = [
    {
      id: 'id',
      accessorKey: 'id',
      header: t('admin:columns.tenant.id'),
      cell: (col) => {
        return <Text size="sm">{col.getValue() as string}</Text>
      },
      withCopyToClipboard: true,
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: t('admin:columns.tenant.name'),
      cell: (col) => {
        return <Text size="sm">{col.getValue() as string}</Text>
      },
    },

    {
      id: 'provider',
      accessorKey: 'provider',
      header: t('admin:columns.tenant.provider'),
      cell: (col) => {
        return <Text size="sm">{col.getValue() as string}</Text>
      },
    },

    {
      id: 'status',
      accessorKey: 'status',
      header: t('admin:columns.tenant.status'),
      cell: (col) => {
        const status = col.getValue() as TenantStatus

        const color = getTenantStatusColor(status)

        return (
          <Text c={color} size="sm">
            {t(`admin:fields.status.${col.getValue() as string}`).toUpperCase()}
          </Text>
        )
      },
    },
  ]

  if (isLoadingTenants) {
    return <Loading size="xl" />
  }

  return (
    <>
      <Table<Tenant>
        title={
          <Flex align="center">
            <Text size="1.375rem" fw={700}>
              {t('pages.tenants')}
            </Text>
            <Text size="1.375rem" c="gray.6" ml={5} fw={700}>
              {`(${tenants?.length})`}
            </Text>
          </Flex>
        }
        actionButtons={() => [
          <ActionIcon onClick={open} size="md" variant="subtle">
            <IconPlus size={20} />
          </ActionIcon>,
        ]}
        columns={columns}
        data={tenants || []}
        onRowClick={(row) => setSelectedTenant(row.original)}
      >
        <Aside close={() => setSelectedTenant(undefined)} opened={!!selectedTenant}>
          {selectedTenant && <AsideEditTenantContent tenant={selectedTenant} close={() => setSelectedTenant(undefined)} />}
        </Aside>
      </Table>
      <AddTenantDrawer opened={opened} close={close} />
    </>
  )
}
