import { Alert, Box, Button, Card, Center, Combobox, Group, Pill, PillsInput, Text, useCombobox } from '@mantine/core'
import { useInterval } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { defaultRegions } from 'api/dto/region'
import { TenantStatus } from 'api/dto/tenant'
import { useGenerateOnboardingTemplate } from 'api/query/onboarding'
import { useGetTenantMe } from 'api/query/tenant'
import { PageLoadingAnimation } from 'components/common/page-loading-animation'
import { useOnboarding } from 'hooks/useOnboarding'
import { NS } from 'i18n'
import { useOnboardingContext } from 'layout/onboarding.layout'
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OnboardingSteps } from 'utils/steps'

const STATUS_CHECK_INTERVAL = 5000 // milliseconds
const STATUS_CHECK_TIMEOUT = 300000 // milliseconds

const ConnectAccountPanel = ({
  connectAction,
  hasError,
  selectedRegions,
  setSelectedRegions,
}: {
  connectAction: () => void
  hasError?: boolean
  selectedRegions: string[]
  setSelectedRegions: Dispatch<SetStateAction<string[]>>
}) => {
  const { t } = useTranslation()

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })
  const [displayAlert, setDisplayAlert] = useState(false)

  const closeAlertAction = useCallback(() => {
    setDisplayAlert(false)
  }, [])

  const handleValueSelect = (val: string) =>
    setSelectedRegions((current) => (current.includes(val) ? current.filter((v) => v !== val) : [...current, val]))

  const options = defaultRegions.map((region) => (
    <Combobox.Option disabled={region.id === 'us-east-1' || selectedRegions.includes(region.id)} value={region.id} key={region.name}>
      {region.name}
    </Combobox.Option>
  ))

  const removeRegion = (value: string) => {
    setSelectedRegions((currentValues) => {
      if (value === 'us-east-1') {
        return currentValues
      }

      return currentValues.filter((v) => v !== value)
    })
  }

  const regions = selectedRegions.map((region) => (
    <Pill key={region} withRemoveButton={region != 'us-east-1'} onRemove={() => removeRegion(region)}>
      {defaultRegions.find((r) => r.id === region)?.name}
    </Pill>
  ))

  useEffect(() => {
    if (hasError) {
      setDisplayAlert(true)
    }
  }, [hasError])

  return (
    <Center>
      <Card radius="1rem" p={40} mt={32} maw={738} miw={500}>
        <Text size="1.5rem" lh="28px" c="#373A40" fw={700}>
          {t(`${NS.onboarding}:setup.connectAccountPanel.title`)}
        </Text>
        <Text size="1rem" lh="22px" c="#5C5F66" fw={400}>
          {t(`${NS.onboarding}:setup.connectAccountPanel.text`)}
        </Text>

        <Box mt={12}>
          <Combobox store={combobox} onOptionSubmit={handleValueSelect}>
            <Combobox.DropdownTarget>
              <PillsInput label="Choose your regions" maw={400} onClick={() => combobox.openDropdown()}>
                <Pill.Group>
                  {regions}

                  <Combobox.EventsTarget>
                    <PillsInput.Field onFocus={() => combobox.openDropdown()} onBlur={() => combobox.closeDropdown()} />
                  </Combobox.EventsTarget>
                </Pill.Group>
              </PillsInput>
            </Combobox.DropdownTarget>

            <Combobox.Dropdown>
              <Combobox.Options>{options.length > 0 ? options : <Combobox.Empty>Nothing found...</Combobox.Empty>}</Combobox.Options>
            </Combobox.Dropdown>
          </Combobox>
        </Box>

        {displayAlert && (
          <Alert
            variant="light"
            color="red"
            title={t(`${NS.onboarding}:setup.connectAccountPanel.errorOccurred`)}
            withCloseButton
            onClose={closeAlertAction}
            mt={15}
            mb={15}
          >
            {t(`${NS.onboarding}:setup.connectAccountPanel.errorCreatingStack`)}
          </Alert>
        )}

        <Card.Section mt={20}>
          <Group justify="flex-end">
            <Button size="sm" onClick={connectAction}>
              {t(`${NS.onboarding}:setup.connectAccountPanel.connectYourAccount`)}
            </Button>
          </Group>
        </Card.Section>
      </Card>
    </Center>
  )
}

const ConnectAccountWaitingScreen = ({ regions }: { regions: string[] }) => {
  const { t } = useTranslation()

  return (
    <>
      <Center mt={200}>
        <PageLoadingAnimation loadingText={t(`${NS.onboarding}:setup.waitingScreen.loadingText`)} />
      </Center>
      <Center>
        <Card radius="1rem" p={40} mt={47} w={497}>
          <Text size="1rem" lh="22px" c="#373A40" fw={700} ta="center">
            {t(`${NS.onboarding}:setup.waitingScreen.title`)}
          </Text>
          <Text size="1rem" lh="22px" c="#5C5F66" fw={400} ta="center">
            {t(`${NS.onboarding}:setup.waitingScreen.text`)}
          </Text>

          <Card.Section mt="24px">
            <Center>
              <GenerateOnboardingButton regions={regions} />
            </Center>
          </Card.Section>
        </Card>
      </Center>
    </>
  )
}

const SetupPageHeader = () => {
  const { t } = useTranslation()

  return (
    <>
      <Text size="1.25rem" lh="1.75rem" c="#858585">
        {t(`${NS.onboarding}:setup.title`)}
      </Text>
      <Text size="2rem" lh="2.5rem" c="#000" fw={700}>
        {t(`${NS.onboarding}:setup.description`)}
      </Text>
    </>
  )
}

export function Setup() {
  const { setStep } = useOnboardingContext()
  const { mutateAsync: generateOnboardingTemplate } = useGenerateOnboardingTemplate()
  const { data: tenant, refetch } = useGetTenantMe()
  const { redirectToCorrectStep } = useOnboarding()

  const { start: startInterval, stop: stopInterval, active } = useInterval(() => checkStackStatusChange(), STATUS_CHECK_INTERVAL)
  const [isLoading, setIsLoading] = useState(false)
  const [hasStatusCheckTimedOut, setHasStatusCheckTimedOut] = useState(false)
  const [isStackCreated, setIsStackCreated] = useState(false)
  const [selectedRegions, setSelectedRegions] = useState<string[]>(['us-east-1'])
  const durationRef = useRef(0)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    setStep(OnboardingSteps.Setup)
    redirectToCorrectStep()
  }, [setStep, redirectToCorrectStep])

  useEffect(() => {
    if (tenant?.isOnboardingStep(TenantStatus.ONBOARDING_STEP_2)) {
      setIsStackCreated(true)
    }
  }, [tenant])

  useEffect(() => {
    if (!isLoading || active) {
      return
    }

    startInterval()

    return () => {
      stopInterval()
    }
  }, [active, isLoading, startInterval, stopInterval])

  useEffect(() => {
    if (!hasStatusCheckTimedOut && !isStackCreated) {
      return
    }
    stopInterval()
    setIsLoading(false)
    resetStatusCheck()

    if (hasStatusCheckTimedOut) {
      setHasError(true)
    }

    if (isStackCreated) {
      redirectToCorrectStep()
    }
  }, [hasStatusCheckTimedOut, isStackCreated, redirectToCorrectStep, stopInterval])

  const resetStatusCheck = () => {
    durationRef.current = 0
    setHasStatusCheckTimedOut(false)
  }

  const updateCheckDuration = () => {
    if (STATUS_CHECK_TIMEOUT <= durationRef.current) {
      setHasStatusCheckTimedOut(true)
    }
    durationRef.current += STATUS_CHECK_INTERVAL
  }

  const checkStackStatusChange = useCallback(() => {
    void refetch().finally(() => {
      updateCheckDuration()
    })
  }, [refetch])

  const connectAccount = useCallback(async () => {
    if (isStackCreated) {
      return
    }

    const url = await generateOnboardingTemplate(selectedRegions)

    setHasError(false)
    window.open(url, '_blank')
    setIsLoading(true)
  }, [generateOnboardingTemplate, isStackCreated, selectedRegions])

  return (
    <Box>
      {isLoading && <ConnectAccountWaitingScreen regions={selectedRegions} />}
      {!isLoading && (
        <>
          <Center>
            <Box w={738} mt={110}>
              <SetupPageHeader />
            </Box>
          </Center>
          <ConnectAccountPanel
            connectAction={connectAccount}
            hasError={hasError}
            selectedRegions={selectedRegions}
            setSelectedRegions={setSelectedRegions}
          />
        </>
      )}
    </Box>
  )
}

function GenerateOnboardingButton({ regions }: { regions: string[] }) {
  const { t } = useTranslation()
  const { mutateAsync: generateOnboardingTemplate, isPending: isLoading } = useGenerateOnboardingTemplate()

  return (
    <Button
      size="sm"
      fw="400"
      onClick={async () => {
        const link = await generateOnboardingTemplate(regions)
        await navigator.clipboard.writeText(link)
        showNotification({
          title: t(`${NS.onboarding}:setup.link-copied`),
          message: t(`${NS.onboarding}:setup.link-copied-clipboard`),
          color: 'blue',
        })
      }}
      loading={isLoading}
    >
      {t(`${NS.onboarding}:setup.link`)}
    </Button>
  )
}
