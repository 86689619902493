import { ActionIcon, Flex, MantineStyleProp, Table, Text, Tooltip } from '@mantine/core'
import { useClipboard, useHover } from '@mantine/hooks'
import { IconCopy, IconCheck } from '@tabler/icons-react'

interface CellWithCopyToClipboardProps {
  cell: any
  styles: MantineStyleProp
  classes: Record<string, string>
}

export function CellWithCopyToClipboard({ cell, styles, classes }: CellWithCopyToClipboardProps) {
  const { hovered, ref } = useHover<HTMLTableCellElement>()

  return (
    <Table.Td
      ref={ref}
      key={cell.id}
      className={classes.text}
      style={{
        ...styles,
        verticalAlign: 'middle',
      }}
    >
      <ClipboardText text={cell.getValue() as string} hovered={hovered} />
    </Table.Td>
  )
}

interface ClipboardTextProps {
  text: string
  size?: string
  tooltipText?: string
  hovered?: boolean
}

export function ClipboardText({ text, size = 'sm', tooltipText = 'Copy to clipboard', hovered }: ClipboardTextProps) {
  const clipboard = useClipboard({ timeout: 1000 })

  return (
    <Flex align="center" gap="xs">
      <Text size={size}>{text}</Text>

      <Tooltip label={tooltipText}>
        <ActionIcon
          className="copy-button"
          color={clipboard.copied ? 'green' : 'gray'}
          variant="subtle"
          onClick={(e) => {
            e.stopPropagation()

            clipboard.copy(text)
          }}
          style={{
            visibility: hovered ? 'visible' : 'hidden',
          }}
        >
          {clipboard.copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
        </ActionIcon>
      </Tooltip>
    </Flex>
  )
}
