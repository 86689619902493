import { useMutation, useQuery } from '@tanstack/react-query'
import { RecommendationType } from 'api/domain/entities/recommendation'
import { CostOptimizationRecommendation } from 'api/domain/entities/recommendation/cost-optimization-recommendation'
import { RecommendationAssembler } from 'api/dto/recommendation/recommendation-assembler'
import { CostOptimizationRecommendationDto, RecommendationDto } from 'api/dto/recommendation/recommendation-dto'
import axios from 'config/axios'
import { useCallback } from 'react'
import { useListAccounts } from './account'
import { globalQueryClient } from 'api/client'
import { showNotification } from '@mantine/notifications'

export const useListRecommendations = (type: RecommendationType, tenantId?: string) => {
  const { data: accounts, isLoading: isLoadingAccounts } = useListAccounts(tenantId)
  return useQuery({
    queryKey: ['recommendations', type, tenantId],
    queryFn: async () => {
      const { data } = await axios.get<RecommendationDto[]>(`/tenants/${tenantId}/recommendations`, {
        params: {
          type,
        },
      })
      return data
    },
    select: useCallback((recommendations: RecommendationDto[]) => {
      const assembled = recommendations.map((r) => {
        const accountsNameMap = accounts?.reduce((acc: { [key: string]: string }, account) => {
          if (account.id && account.accountName) {
            acc[account.id] = account.accountName
          }
          return acc
        }, {})

        const assembler = RecommendationAssembler.create(r)
        return assembler ? assembler.fromDto(r as unknown as CostOptimizationRecommendationDto, accountsNameMap) : undefined
      })

      return assembled.filter((r) => r) as CostOptimizationRecommendation[]
    }, []),
    enabled: !isLoadingAccounts && !!tenantId,
    staleTime: 1000 * 60 * 5,
  })
}

export const useGetRecommendation = (type: RecommendationType, tenantId?: string, recommendationId?: string) => {
  const { data: accounts, isLoading: isLoadingAccounts } = useListAccounts(tenantId)

  return useQuery({
    queryKey: ['recommendations', type, recommendationId],
    queryFn: async () => {
      const { data } = await axios.get<RecommendationDto>(`/tenants/${tenantId}/recommendations/${recommendationId}`, {
        params: {
          type,
        },
      })
      return data
    },
    select: useCallback(
      (recommendation: RecommendationDto) => {
        const accountsNameMap = accounts?.reduce((acc: { [key: string]: string }, account) => {
          if (account.id && account.accountName) {
            acc[account.id] = account.accountName
          }
          return acc
        }, {})
        const assembler = RecommendationAssembler.create(recommendation)
        return assembler ? assembler.fromDto(recommendation as unknown as CostOptimizationRecommendationDto, accountsNameMap) : undefined
      },
      [accounts],
    ),
    enabled: !isLoadingAccounts && !!tenantId && !!recommendationId,
    staleTime: 1000 * 60 * 5,
  })
}

export const useToggleIgnoreCostRecommendationResource = () => {
  return useMutation({
    mutationFn: async ({ tenantId, recommendationId, resourceId }: { tenantId: string; recommendationId: string; resourceId: string }) => {
      return axios.put(`/tenants/${tenantId}/recommendations/${recommendationId}/resources/${resourceId}/toggle-ignore`)
    },
    onSuccess: (_, { tenantId, recommendationId }) => {
      showNotification({
        title: 'Resource updated',
        message: 'Resource has changed status',
      })
    },
    onSettled: () => {
      void globalQueryClient.invalidateQueries({
        queryKey: ['recommendations', RecommendationType.COST_OPTIMIZATION],
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['recommendations', RecommendationType.COST_OPTIMIZATION],
      })
    },
  })
}
