interface FormatStringOptions {
  fractionDigits?: number
  suffix?: string
}

export function formatString(value?: string, options?: FormatStringOptions): string {
  const { fractionDigits = 2, suffix = '' } = options || {}
  const number = Number(value)

  if (isNaN(number)) {
    return '-'
  }

  return number.toLocaleString(undefined, { minimumFractionDigits: fractionDigits }) + suffix
}

export function formatCost(number?: number, fractionDigits = 2): string {
  if (number === undefined) {
    return '-'
  }
  return number.toLocaleString(undefined, { minimumFractionDigits: fractionDigits }) + '$'
}

export function formaPercentage(number?: number, fractionDigits = 2): string {
  if (number === undefined) {
    return '-'
  }
  return (number * 100).toLocaleString(undefined, { minimumFractionDigits: fractionDigits }) + '%'
}
