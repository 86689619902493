import { Box, Divider, Flex, Grid, List, Text, Title } from '@mantine/core'
import {
  CostOptimizationRecommendation,
  CostOptimizationResource,
} from 'api/domain/entities/recommendation/cost-optimization-recommendation'
import { useTranslation } from 'react-i18next'
import { DateTimeFormat } from 'utils/date-time'
import { getDifficultyColor } from 'utils/difficulty'
import ChevronIcon from 'assets/icons/chevron-right.svg?react'
import { useNavigate } from 'react-router-dom'
import { RecommendationStatus } from 'api/domain/entities/recommendation'
import { ResourceStatus } from 'api/domain/entities/resource/resource-status'

interface AsideCostRecommendationContentProps {
  costOptimizationRecommendation: CostOptimizationRecommendation
}

export function AsideCostRecommendationContent({ costOptimizationRecommendation }: AsideCostRecommendationContentProps) {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      <Text c="dark.7" lh="24px" size="1rem" fw={700}>
        {costOptimizationRecommendation.translatedTitle}
      </Text>
      <Text mt={20} c="dark.7" lh="24px" size="1rem" fw={500}>
        {costOptimizationRecommendation.translatedDescription}
      </Text>
      <Grid mt={20}>
        <Grid.Col span={6}>
          <Box>
            <Text c="dark.7" lh="21.7px" size="0.875rem" fw={600}>
              {t('cost:fields.difficulty.title')}
            </Text>
            <Text mt={5} c={getDifficultyColor(costOptimizationRecommendation.difficulty)} lh="21.7px" size="0.875rem" fw={400}>
              {t(`cost:fields.difficulty.${costOptimizationRecommendation.difficulty}`).toUpperCase()}
            </Text>
          </Box>
          <Box mt={10}>
            <Text c="dark.7" lh="21.7px" size="0.875rem" fw={600}>
              {t('cost:fields.annualized-cost')}
            </Text>
            <Text mt={5} c="dark.7" lh="21.7px" size="0.875rem" fw={400}>
              {costOptimizationRecommendation.globalCurrentCost.toFixed(2)}$
            </Text>
          </Box>
        </Grid.Col>
        <Grid.Col span={6}>
          <Box>
            <Text c="dark.7" lh="21.7px" size="0.875rem" fw={600}>
              {t('cost:fields.annualized-savings')}
            </Text>
            <Text mt={5} c="dark.7" lh="21.7px" size="0.875rem" fw={400}>
              {costOptimizationRecommendation.globalAnnualizedSavings.toFixed(2)}$
            </Text>
          </Box>
        </Grid.Col>
      </Grid>
      <Divider mt={12} mb={12} />

      <Flex
        mt={10}
        p={12}
        sx={(theme) => ({
          borderRadius: theme.radius.md,
          border: `1px solid ${theme.colors.gray[3]}`,
          cursor: costOptimizationRecommendation.resources.length ? 'pointer' : 'default',
        })}
        justify="space-between"
        align="center"
        onClick={() => {
          if (costOptimizationRecommendation.resources.length) {
            navigate(`/cost-recommendations/${costOptimizationRecommendation.id}?status=${ResourceStatus.UNRESOLVED}`)
          }
        }}
      >
        <Box>
          <Text c="dark.7" lh="21.7px" size="1rem" fw={700}>
            {t('cost:fields.resources-affected')} ({costOptimizationRecommendation.unresolvedResources.length})
          </Text>
          <Text c="dark.2" lh="21.7px" size="0.625rem" fw={700}>
            {t('cost:fields.number-of-affected-resources')}
          </Text>
        </Box>
        <ChevronIcon />
      </Flex>

      <Flex
        mt={10}
        p={12}
        sx={(theme) => ({
          borderRadius: theme.radius.md,
          border: `1px solid ${theme.colors.gray[3]}`,
          cursor: costOptimizationRecommendation.resources.length ? 'pointer' : 'default',
        })}
        justify="space-between"
        align="center"
        onClick={() => {
          if (costOptimizationRecommendation.resources.length) {
            navigate(`/cost-recommendations/${costOptimizationRecommendation.id}?status=${ResourceStatus.RESOLVED}`)
          }
        }}
      >
        <Box>
          <Text c="dark.7" lh="21.7px" size="1rem" fw={700}>
            {t('cost:fields.resolved-resources')} ({costOptimizationRecommendation.resolvedResources.length})
          </Text>
          <Text c="dark.2" lh="21.7px" size="0.625rem" fw={700}>
            {t('cost:fields.number-of-resolved-resources')}
          </Text>
        </Box>
        <ChevronIcon />
      </Flex>

      <Flex
        mt={10}
        p={12}
        sx={(theme) => ({
          borderRadius: theme.radius.md,
          border: `1px solid ${theme.colors.gray[3]}`,
          cursor: costOptimizationRecommendation.resources.length ? 'pointer' : 'default',
        })}
        justify="space-between"
        align="center"
        onClick={() => {
          if (costOptimizationRecommendation.resources.length) {
            navigate(`/cost-recommendations/${costOptimizationRecommendation.id}?status=${ResourceStatus.OUTDATED}`)
          }
        }}
      >
        <Box>
          <Text c="dark.7" lh="21.7px" size="1rem" fw={700}>
            {t('cost:fields.outdated-resources')} ({costOptimizationRecommendation.outdatedResources.length})
          </Text>
          <Text c="dark.2" lh="21.7px" size="0.625rem" fw={700}>
            {t('cost:fields.number-of-outdated-resources')}
          </Text>
        </Box>
        <ChevronIcon />
      </Flex>

      <Flex
        mt={10}
        p={12}
        sx={(theme) => ({
          borderRadius: theme.radius.md,
          border: `1px solid ${theme.colors.gray[3]}`,
          cursor: costOptimizationRecommendation.resources.length ? 'pointer' : 'default',
        })}
        justify="space-between"
        align="center"
        onClick={() => {
          if (costOptimizationRecommendation.resources.length) {
            navigate(`/cost-recommendations/${costOptimizationRecommendation.id}?status=${ResourceStatus.IGNORED}`)
          }
        }}
      >
        <Box>
          <Text c="dark.7" lh="21.7px" size="1rem" fw={700}>
            {t('cost:fields.ignored-resources')} ({costOptimizationRecommendation.ignoredResources.length})
          </Text>
          <Text c="dark.2" lh="21.7px" size="0.625rem" fw={700}>
            {t('cost:fields.number-of-ignored-resources')}
          </Text>
        </Box>
        <ChevronIcon />
      </Flex>
    </>
  )
}
