import { CostOptimizationRecommendationType } from 'api/domain/entities/recommendation/cost-optimization-recommendation'
import { formatString } from './number'
import { DateTime, DateTimeFormat } from './date-time'

interface MergedColumns {
  id: string
  parser: (value: any) => string
}

interface AdditionalColumn {
  key: string
  headerKey?: string
  parser?: (value: any) => string
  hideIfEmpty?: boolean
}

interface WarningColumn {
  key: string
  parser?: (value: any) => string
}

const additionalColumns: Record<CostOptimizationRecommendationType, AdditionalColumn[]> = {
  [CostOptimizationRecommendationType.EBSGP2ToGP3]: [
    { key: 'volumeType' },
    { key: 'size', parser: (value) => formatString(value, { suffix: ' GB' }) },
    { key: 'iops' },
    { key: 'throughput', parser: (value) => (value ? value + ' MB/s' : '') },
    { key: 'estimatedVolumeSizeGb', parser: (value) => formatString(value, { suffix: ' GB' }) },
  ],
  [CostOptimizationRecommendationType.EBSIO1ToGP3]: [
    { key: 'volumeType' },
    { key: 'size', parser: (value) => formatString(value, { suffix: ' GB' }) },
    { key: 'iops' },
    { key: 'throughput', parser: (value) => (value ? value + ' MB/s' : '') },
    { key: 'estimatedVolumeSizeGb', parser: (value) => formatString(value, { suffix: ' GB' }) },
  ],
  [CostOptimizationRecommendationType.VPCUnusedNatGateways]: [
    { key: 'usageMbLast31Days', parser: (value) => formatString(value, { suffix: ' MB' }) },
  ],
  [CostOptimizationRecommendationType.VPCUnusedVPCEndpoints]: [
    { key: 'usageMbLast31Days', parser: (value) => formatString(value, { suffix: ' MB' }) },
  ],
  [CostOptimizationRecommendationType.EC2UnusedLoadBalancers]: [
    { key: 'usageMbLast31Days', parser: (value) => formatString(value, { suffix: ' MB' }) },
  ],
  [CostOptimizationRecommendationType.EC2UnusedElasticIps]: [
    { key: 'publicIp' },
    { key: 'associatedInstanceId', hideIfEmpty: true },
    { key: 'stoppedAtTime', hideIfEmpty: true },
  ],
  [CostOptimizationRecommendationType.RDSGP2ToGP3]: [
    { key: 'volumeType' },
    { key: 'size', parser: (value) => formatString(value, { suffix: ' GB' }) },
    { key: 'iops' },
    { key: 'throughput', parser: (value) => (value ? value + ' MB/s' : '') },
    { key: 'estimatedVolumeSizeGb', parser: (value) => formatString(value, { suffix: ' GB' }) },
  ],
  [CostOptimizationRecommendationType.RDSIO1ToGP3]: [
    { key: 'volumeType' },
    { key: 'size', parser: (value) => formatString(value, { suffix: ' GB' }) },
    { key: 'iops' },
    { key: 'throughput', parser: (value) => (value ? value + ' MB/s' : '') },
    { key: 'estimatedVolumeSizeGb', parser: (value) => formatString(value, { suffix: ' GB' }) },
  ],
  [CostOptimizationRecommendationType.RDSExtendedSupport]: [
    { key: 'dbEngine' },
    { key: 'dbEngineMajorVersion' },
    { key: 'estimatedDbVcpu', parser: (value) => formatString(value, { suffix: ' vCPU' }) },
  ],
  [CostOptimizationRecommendationType.S3IntelligentTiering]: [
    { key: 'averageNumberOfObjects', parser: (value) => formatString(value, { suffix: ' objects', fractionDigits: 0 }) },
    { key: 'estimatedGbMonths', parser: (value) => formatString(value, { suffix: ' GB' }) },
    { key: 'totalEstimatedGbMonthsIntelligentTiering', parser: (value) => formatString(value, { suffix: ' GB' }) },
  ],
  [CostOptimizationRecommendationType.RDSAuroraGravitonInstances]: [{ key: 'currentInstanceType' }, { key: 'targetInstanceType' }],
  [CostOptimizationRecommendationType.RDSReservedInstance]: [{ key: 'instanceType' }, { key: 'deploymentOption' }, { key: 'dbEngine' }],
  [CostOptimizationRecommendationType.ElastiCacheReservedInstance]: [{ key: 'cacheEngine' }, { key: 'engineVersion' }],
  [CostOptimizationRecommendationType.OpenSearchReservedInstance]: [{ key: 'instanceType' }, { key: 'engineVersion' }],
  [CostOptimizationRecommendationType.ElastiCacheRedisToValkey]: [{ key: 'engine' }, { key: 'engineVersion' }],
  [CostOptimizationRecommendationType.ElastiCacheGravitonInstances]: [
    { key: 'cacheEngine' },
    { key: 'engineVersion' },
    { key: 'currentCacheNodeType' },
    { key: 'upgradedCacheNodeType', headerKey: 'targetCacheNodeType' },
  ],
  [CostOptimizationRecommendationType.ElastiCacheIdleInstance]: [
    { key: 'cacheEngine' },
    { key: 'engineVersion' },
    {
      key: 'idleSince',
      hideIfEmpty: true,
      parser: (value) => (value ? DateTime.fromISOString(value).toFormat(DateTimeFormat.YearMonthAndDayWithTime) : ''),
    },
  ],
  [CostOptimizationRecommendationType.EFSIntelligentTiering]: [
    { key: 'totalSizeInGb', parser: (value) => formatString(value, { suffix: ' GB' }) },
    { key: 'standardSizeInGb', parser: (value) => formatString(value, { suffix: ' GB' }) },
    { key: 'iaSizeInGb', parser: (value) => formatString(value, { suffix: ' GB' }) },
    { key: 'archiveSizeInGb', parser: (value) => formatString(value, { suffix: ' GB' }) },
  ],
  [CostOptimizationRecommendationType.RDSDeleteIdleInstances]: [
    { key: 'engine' },
    { key: 'engineVersion' },
    { key: 'maximumDatabaseConnectionsLast30Days', parser: (value) => (value ? Number(value).toString() : '') },
  ],
  [CostOptimizationRecommendationType.EFSDeleteUnusedVolumes]: [
    { key: 'totalSizeInGb', parser: (value) => formatString(value, { suffix: ' GB' }) },
    { key: 'standardSizeInGb', parser: (value) => formatString(value, { suffix: ' GB' }) },
    { key: 'iaSizeInGb', parser: (value) => formatString(value, { suffix: ' GB' }) },
    { key: 'archiveSizeInGb', parser: (value) => formatString(value, { suffix: ' GB' }) },
  ],
  [CostOptimizationRecommendationType.EBSDeleteUnusedVolumes]: [
    { key: 'volumeType' },
    { key: 'size', parser: (value) => formatString(value, { suffix: ' GB' }) },
    { key: 'iops' },
    { key: 'throughput', parser: (value) => (value ? value + ' MB/s' : '') },
    {
      key: 'stoppedAtTime',
      hideIfEmpty: true,
      parser: (value) => (value ? DateTime.fromISOString(value).toFormat(DateTimeFormat.YearMonthAndDayWithTime) : ''),
    },
  ],
  [CostOptimizationRecommendationType.EBSDeleteVolumesAttachedToStoppedInstance]: [
    { key: 'volumeType' },
    { key: 'size', parser: (value) => formatString(value, { suffix: ' GB' }) },
    { key: 'iops' },
    { key: 'throughput', parser: (value) => (value ? value + ' MB/s' : '') },
    {
      key: 'stoppedAtTime',
      hideIfEmpty: true,
      parser: (value) => DateTime.fromISOString(value).toFormat(DateTimeFormat.YearMonthAndDayWithTime),
    },
  ],
  [CostOptimizationRecommendationType.EC2StopIdleInstances]: [
    { key: 'instanceType' },
    { key: 'instanceName' },
    { key: 'platform' },
    { key: 'maximumCpuUtilization', parser: (value) => (value ? Number(value).toString() : '') },
  ],
  [CostOptimizationRecommendationType.OpenSearchGravitonInstances]: [
    { key: 'instanceType' },
    { key: 'engine' },
    { key: 'engineVersion' },
    { key: 'upgradedInstanceType', headerKey: 'targetInstanceType' },
  ],
}

export function getAdditionalColumnsByRecommendationType(costOptimizationRecommendationType: CostOptimizationRecommendationType) {
  return additionalColumns[costOptimizationRecommendationType] || []
}

const warningAdditionalColumns: Record<CostOptimizationRecommendationType, WarningColumn[]> = {
  [CostOptimizationRecommendationType.EBSGP2ToGP3]: [],
  [CostOptimizationRecommendationType.EBSIO1ToGP3]: [],
  [CostOptimizationRecommendationType.VPCUnusedNatGateways]: [],
  [CostOptimizationRecommendationType.VPCUnusedVPCEndpoints]: [],
  [CostOptimizationRecommendationType.EC2UnusedLoadBalancers]: [],
  [CostOptimizationRecommendationType.EC2UnusedElasticIps]: [],
  [CostOptimizationRecommendationType.RDSGP2ToGP3]: [],
  [CostOptimizationRecommendationType.RDSIO1ToGP3]: [],
  [CostOptimizationRecommendationType.RDSExtendedSupport]: [],
  [CostOptimizationRecommendationType.S3IntelligentTiering]: [],
  [CostOptimizationRecommendationType.ElastiCacheReservedInstance]: [],
  [CostOptimizationRecommendationType.OpenSearchReservedInstance]: [],
  [CostOptimizationRecommendationType.RDSAuroraGravitonInstances]: [
    {
      key: 'riArns',
      parser: (value) =>
        value
          ?.split(',')
          .map((v: string) => `- ${v}`)
          .join('\n'),
    },
  ],
  [CostOptimizationRecommendationType.RDSReservedInstance]: [],
  [CostOptimizationRecommendationType.ElastiCacheRedisToValkey]: [],
  [CostOptimizationRecommendationType.ElastiCacheGravitonInstances]: [],
  [CostOptimizationRecommendationType.ElastiCacheIdleInstance]: [],
  [CostOptimizationRecommendationType.EFSIntelligentTiering]: [],
  [CostOptimizationRecommendationType.RDSDeleteIdleInstances]: [],
  [CostOptimizationRecommendationType.EFSDeleteUnusedVolumes]: [],
  [CostOptimizationRecommendationType.EBSDeleteUnusedVolumes]: [],
  [CostOptimizationRecommendationType.EBSDeleteVolumesAttachedToStoppedInstance]: [],
  [CostOptimizationRecommendationType.EC2StopIdleInstances]: [],
  [CostOptimizationRecommendationType.OpenSearchGravitonInstances]: [],
}

export function getWarningColumnsByRecommendationType(costOptimizationRecommendationType: CostOptimizationRecommendationType) {
  return warningAdditionalColumns[costOptimizationRecommendationType] || []
}

const mergedAdditionalColumns: Record<CostOptimizationRecommendationType, MergedColumns[]> = {
  [CostOptimizationRecommendationType.EBSGP2ToGP3]: [],
  [CostOptimizationRecommendationType.EBSIO1ToGP3]: [],
  [CostOptimizationRecommendationType.VPCUnusedNatGateways]: [],
  [CostOptimizationRecommendationType.VPCUnusedVPCEndpoints]: [],
  [CostOptimizationRecommendationType.EC2UnusedLoadBalancers]: [],
  [CostOptimizationRecommendationType.EC2UnusedElasticIps]: [],
  [CostOptimizationRecommendationType.RDSGP2ToGP3]: [],
  [CostOptimizationRecommendationType.RDSIO1ToGP3]: [],
  [CostOptimizationRecommendationType.RDSExtendedSupport]: [],
  [CostOptimizationRecommendationType.S3IntelligentTiering]: [],
  [CostOptimizationRecommendationType.RDSAuroraGravitonInstances]: [],
  [CostOptimizationRecommendationType.RDSReservedInstance]: [
    {
      id: 'instanceToReserve',
      parser: (row) => {
        return `${Math.round(row.additionalFields?.numberOfInstancesTypeToReserve)}x ${row.additionalFields?.instanceTypeToReserve} Single-AZ`
      },
    },
    {
      id: 'term',
      parser: () => '1 Year No Upfront',
    },
  ],
  [CostOptimizationRecommendationType.ElastiCacheReservedInstance]: [
    {
      id: 'instanceToReserve',
      parser: (row) => {
        return `${Math.round(row.additionalFields?.numberOfInstancesTypeToReserve)}x ${row.additionalFields?.instanceTypeToReserve}`
      },
    },
    {
      id: 'term',
      parser: () => '1 Year No Upfront',
    },
  ],
  [CostOptimizationRecommendationType.OpenSearchReservedInstance]: [
    {
      id: 'instanceToReserve',
      parser: (row) => {
        return `${Math.round(row.additionalFields?.averageNumberOfNodes)}x ${row.additionalFields?.instanceType}`
      },
    },
    {
      id: 'term',
      parser: () => '1 Year No Upfront',
    },
  ],
  [CostOptimizationRecommendationType.ElastiCacheRedisToValkey]: [],
  [CostOptimizationRecommendationType.ElastiCacheGravitonInstances]: [],
  [CostOptimizationRecommendationType.ElastiCacheIdleInstance]: [],
  [CostOptimizationRecommendationType.EFSIntelligentTiering]: [],
  [CostOptimizationRecommendationType.RDSDeleteIdleInstances]: [],
  [CostOptimizationRecommendationType.EFSDeleteUnusedVolumes]: [],
  [CostOptimizationRecommendationType.EBSDeleteUnusedVolumes]: [],
  [CostOptimizationRecommendationType.EBSDeleteVolumesAttachedToStoppedInstance]: [],
  [CostOptimizationRecommendationType.EC2StopIdleInstances]: [],
  [CostOptimizationRecommendationType.OpenSearchGravitonInstances]: [],
}

export function getMergedColumnsByRecommendationType(costOptimizationRecommendationType: CostOptimizationRecommendationType) {
  return mergedAdditionalColumns[costOptimizationRecommendationType] || []
}
