import { CostOptimizationRecommendationDto, CostOptimizationResourceDto } from 'api/dto/recommendation/recommendation-dto'
import { Recommendation } from './recommendation'
import { I18nString } from 'api/dto/i18n-string'
import { ResourceStatus } from '../resource/resource-status'

export enum CostOptimizationRecommendationType {
  EBSGP2ToGP3 = 'EBS:GP2toGP3',
  EBSIO1ToGP3 = 'EBS:IO1toGP3',
  EBSDeleteUnusedVolumes = 'EBS:DeleteUnusedVolumes',
  VPCUnusedNatGateways = 'VPC:UnusedNatGateways',
  VPCUnusedVPCEndpoints = 'VPC:UnusedVPCEndpoints',
  EC2UnusedLoadBalancers = 'EC2:UnusedLoadBalancers',
  EC2UnusedElasticIps = 'EC2:UnusedElasticIps',
  EC2StopIdleInstances = 'EC2:StopIdleInstances',
  RDSGP2ToGP3 = 'RDS:GP2toGP3',
  RDSIO1ToGP3 = 'RDS:IO1toGP3',
  RDSExtendedSupport = 'RDS:ExtendedSupport',
  S3IntelligentTiering = 'S3:IntelligentTiering',
  RDSReservedInstance = 'RDS:ReservedInstance',
  RDSAuroraGravitonInstances = 'RDS:AuroraGravitonInstances',
  OpenSearchReservedInstance = 'OpenSearch:ReservedInstance',
  OpenSearchGravitonInstances = 'OpenSearch:GravitonInstances',
  ElastiCacheReservedInstance = 'ElastiCache:ReservedInstance',
  ElastiCacheRedisToValkey = 'ElastiCache:RedisToValkey',
  ElastiCacheGravitonInstances = 'ElastiCache:GravitonInstances',
  ElastiCacheIdleInstance = 'ElastiCache:IdleInstance',
  EBSDeleteVolumesAttachedToStoppedInstance = 'EBS:DeleteVolumesAttachedToStoppedInstance',
  EFSIntelligentTiering = 'EFS:IntelligentTiering',
  RDSDeleteIdleInstances = 'RDS:DeleteIdleClusters',
  EFSDeleteUnusedVolumes = 'EFS:DeleteUnusedVolumes',
}

export enum CostOptimizationRecommendationDifficulty {
  EASY = 'easy',
  MEDIUM = 'medium',
  ADVANCED = 'advanced',
}

export class CostOptimizationResource implements CostOptimizationResource {
  id: string
  accountId: string
  accountName?: string
  resourceId?: string
  resourceName?: string
  annualizedCost?: number
  annualizedSavings?: number
  savingPercentage?: number
  realizedSavings?: number
  status: ResourceStatus
  region: string
  service?: string

  // Top 25 resources
  last7DaysCost?: number
  last31DaysCost?: number

  additionalFields: Record<string, any>

  constructor(resourceDto: CostOptimizationResourceDto, accountsNameMap?: Record<string, string>) {
    this.id = resourceDto.id
    this.accountId = resourceDto.accountId
    this.resourceId = resourceDto.resourceId
    this.resourceName = resourceDto.resourceName
    this.annualizedCost = resourceDto.annualizedCost
    this.annualizedSavings = resourceDto.annualizedSavings
    this.savingPercentage = resourceDto.savingPercentage
    this.status = resourceDto.status
    this.region = resourceDto.region
    this.service = resourceDto.service
    this.realizedSavings = resourceDto.realizedSavings

    this.last7DaysCost = resourceDto.last7DaysCost
    this.last31DaysCost = resourceDto.last31DaysCost

    this.additionalFields = resourceDto.additionalFields || {}

    if (accountsNameMap) {
      this.accountName = accountsNameMap[this.accountId] || ''
    }
  }

  get isResolved(): boolean {
    return this.status === ResourceStatus.RESOLVED
  }

  get isUnresolved(): boolean {
    return this.status === ResourceStatus.UNRESOLVED
  }

  get isOutdated(): boolean {
    return this.status === ResourceStatus.OUTDATED
  }

  get isIgnored(): boolean {
    return this.status === ResourceStatus.IGNORED
  }
}

export class CostOptimizationRecommendation extends Recommendation {
  costOptimizationType: CostOptimizationRecommendationType
  resources: CostOptimizationResource[]
  globalCurrentCost: number
  globalAnnualizedSavings: number
  globalRealizedSavings: number
  difficulty: CostOptimizationRecommendationDifficulty
  selectionCriteria?: I18nString[]

  constructor(recommendationDto: CostOptimizationRecommendationDto, accountsNameMap?: Record<string, string>) {
    super(recommendationDto)

    this.costOptimizationType = recommendationDto.costOptimizationType
    this.resources = recommendationDto.resources.map((r) => new CostOptimizationResource(r, accountsNameMap))
    this.globalCurrentCost = recommendationDto.globalCurrentCost
    this.globalAnnualizedSavings = recommendationDto.globalAnnualizedSavings
    this.globalRealizedSavings = recommendationDto.globalRealizedSavings
    this.difficulty = recommendationDto.difficulty
    this.selectionCriteria = recommendationDto.selectionCriteria
  }

  get unresolvedResources(): CostOptimizationResource[] {
    return this.resources.filter((r) => r.isUnresolved)
  }

  get resolvedResources(): CostOptimizationResource[] {
    return this.resources.filter((r) => r.isResolved)
  }

  get outdatedResources(): CostOptimizationResource[] {
    return this.resources.filter((r) => r.isOutdated)
  }

  get ignoredResources(): CostOptimizationResource[] {
    return this.resources.filter((r) => r.isIgnored)
  }
}
